import React from 'react'
import Seo from '../components/layout/SEO'
import { Link } from 'gatsby'
import Layout from '../components/layout/Layout404'

export default function page404() {
  return (
    <Layout>
      <Seo
        title='404 Not Found'
      />
      <div id="attorna-full-no-header-wrap" className="attorna-not-found-wrap">
        <div className="attorna-not-found-background"></div>
        <div className="attorna-not-found-container attorna-container">
          <div className="attorna-header-transparent-substitute"></div>
          <div className="attorna-not-found-content attorna-item-pdlr">
            <h1 className="attorna-not-found-head">404</h1>
            <h3 className="attorna-not-found-title attorna-content-font">Page Not Found</h3>
            <div className="attorna-not-found-caption">Sorry, we couldn't find the page you're looking for.</div>
            {/* search form */}
            <div className="attorna-not-found-back-to-home">
              <Link to='/'>Back to Homepage</Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
